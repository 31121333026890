import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { allTranslations } from './all'
import { search } from './search/search'
import messenger from './messenger'

const flatten = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object') Object.assign(acc, flatten(obj[k], pre + k))
    else acc[pre + k] = obj[k]
    return acc
  }, {})

i18n.use(LanguageDetector).init({
  detection: {
    // order and from where user language should be detected
    order: ['cookie', 'navigator'],
    // keys or params to lookup language from
    lookupLocalStorage: 'i18nextLng',
    // cache user language on
    caches: ['cookie']
  },
  // we init with resources
  resources: {
    fr: {
      translations: {
        ...allTranslations.fr,
        ...search.fr,
        ...flatten(messenger.fr, 'messenger')
      }
    },
    en: {
      translations: {
        ...allTranslations.en,
        ...search.en,
        ...flatten(messenger.en, 'messenger')
      }
    },
    it: {
      translations: {
        ...allTranslations.it,
        ...search.it,
        ...flatten(messenger.it, 'messenger')
      }
    },
    es: {
      translations: {
        ...allTranslations.es,
        ...search.es,
        ...flatten(messenger.es, 'messenger')
      }
    },
    de: {
      translations: {
        ...allTranslations.de,
        ...search.de,
        ...flatten(messenger.de, 'messenger')
      }
    }
  },
  fallbackLng: 'fr',
  debug: true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
})

export default i18n
