import en from './json/all_en.json'
import fr from './json/all_fr.json'
import it from './json/all_it.json'
import de from './json/all_de.json'
import es from './json/all_es.json'

export const allTranslations = {
  en,
  fr,
  it,
  de,
  es
}
