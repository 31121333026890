// eslint-disable-next-line
export const imageSvg =
  'M12.464 26.304c-.464 0-.824-.128-1.08-.384-.256-.256-.384-.608-.384-1.056V10.8c0-.464.14-.836.42-1.116.28-.28.652-.42 1.116-.42.48 0 .86.14 1.14.42.28.28.42.652.42 1.116v12.96h6.744c.96 0 1.44.424 1.44 1.272 0 .848-.48 1.272-1.44 1.272h-8.376zm13.08.144c-.432 0-.788-.124-1.068-.372s-.42-.604-.42-1.068v-9.264c0-.464.14-.816.42-1.056.28-.24.636-.36 1.068-.36.432 0 .792.12 1.08.36.288.24.432.592.432 1.056v9.264c0 .464-.144.82-.432 1.068-.288.248-.648.372-1.08.372zm0-14.256c-.544 0-.976-.148-1.296-.444-.32-.296-.48-.684-.48-1.164 0-.48.16-.864.48-1.152C24.568 9.144 25 9 25.544 9c.528 0 .956.144 1.284.432.328.288.492.672.492 1.152 0 .48-.16.868-.48 1.164-.32.296-.752.444-1.296.444z'

// eslint-disable-next-line
export const btnSvg =
  'M12.5 11h-.79l-.28-.27A6.471 6.471 0 0 0 13 6.5 6.5 6.5 0 1 0 6.5 13c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L17.49 16l-4.99-5zm-6 0C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11z'

// eslint-disable-next-line
export const notificationSvg =
  'M8 20c1.1 0 2-.9 2-2H6a2 2 0 0 0 2 2zm6-6V9c0-3.07-1.64-5.64-4.5-6.32V2C9.5 1.17 8.83.5 8 .5S6.5 1.17 6.5 2v.68C3.63 3.36 2 5.92 2 9v5l-2 2v1h16v-1l-2-2z'

// eslint-disable-next-line
export const chatSvg =
  'M18 0H2C.9 0 .01.9.01 2L0 20l4-4h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM4 7h12v2H4V7zm8 5H4v-2h8v2zm4-6H4V4h12v2z'

// eslint-disable-next-line
export const feedOptionSvg =
  'M12 0H2C.9 0 .01.9.01 2L0 18l7-3 7 3V2c0-1.1-.9-2-2-2zm0 15l-5-2.18L2 15V2h10v13z'

// eslint-disable-next-line
export const recommandSvg =
  'M0 20h4V8H0v12zM22 9c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L13.17 0 6.59 6.59C6.22 6.95 6 7.45 6 8v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9.09l-.01-.01L22 9z'

// eslint-disable-next-line
export const commentSvg =
  'M9.995 1c0-.55-.445-1-.995-1H1C.45 0 0 .45 0 1v6c0 .55.45 1 1 1h7l2 2-.005-9zM8 6H2V5h6v1zm0-1.5H2v-1h6v1zM8 3H2V2h6v1z'

// eslint-disable-next-line
export const shareSvg =
  'M15 14.08c-.76 0-1.44.3-1.96.77L5.91 10.7c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L5.04 7.81C4.5 7.31 3.79 7 3 7c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92 0-1.61-1.31-2.92-2.92-2.92z'

// eslint-disable-next-line
export const hamburgerSvg =
  'M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'

export { default as DEFAULT_PICTURE_PROFIL } from '../../resources/images/img-profil-holder.png'
export { default as DEFAULT_PICTURE_GROUP } from '../../resources/images/groupe-empty-icon.svg'
export { default as DEFAULT_PICTURE_BKG_ANNONCE } from '../../resources/images/bkg_annonce_default.png'
//logo desktop
export { default as logoLkv3x } from '../../resources/images/logo-linkinnov-@3x.png'
export { default as logoImage } from '../../resources/images/logo-hor-noir_tagline.png'

//Logo Mobile

export const logoImageMobile =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAgCAYAAADwvkPPAAAAAXNSR0IArs4c6QAABItJREFUSA2tlF1oHUUUx8/M7r3NbWqbSD68NzYfVtEIoVWsrbaVtIYg6Eultw99EB/0wadWEAlUMaIQBPvQJ7GEPihUKPgUX8SP3FZM8iBqrZIbSIWoxLYJ+brm7uzO7oznzO7c3CQ3FaEDy5mZPec3/3PO7ELrUrEL7tLgTOrPs7cnh+4GzyWIAhhonS+Gt5oeefu/oOMvL/ZqBYPAoZMBm0ilt735xIXMHxTH7pub/FFreMwsGB+82fzwu1sBr55afjJS0Rgw7TDGrNuMW9fYc/giK3G7Q1ZrNZi9XXyreq96Xg6CM4FUThAo8P3IPDjv8FYWTpDfOhhtKNDvtc5PDdB84wgCaPOlgkAC+FKj1eATWEY58t0EMwClhrJzU69vhEmpx00wAtZAGmTEJ2KYRlPjUVp/mLtVPFQNjKQ7FIbwKwEpVVKIB1w8/kXTN+TngkcNJdqmwRWw07j7vX1z8ut7l0d79f4ZdfMklr+LOWzipdHWL+17VwlscJKtxrZSl8jGg+23jtYeLTCB80/smuxIn9cOSkcuCFSWxCIGXxG8AmuoDqo1v9y/1OVF3hWMesflwgGl4mC6OiTKXiENunaDEupnzy12ikAUQLPdHKNc5aF/kpbVY22FWkPS8LHZjpK/WsCStFNpEEYNuNPhlVu+Dne+b7b9n1AU8G0HFYWqgpZhzZw1R5ujbQDbfNC5/undwi8VMKiTMiBFWA7g6OvWRYyJwCogSy7xupIu7tAY7J1qKgViFFPrIkWmtjqxpKw7FzZO/p4GEfBK8bcS5kXlj5CxhxypTgxBdLixuHYbdkTy8N4yFKfr4K/ZbUYynWpGYmj+Rv+1FiH84zQ324ls8iUwdpS5dSk9i+gHe7o9aM9J+O2XHbC0ktSqClbyvb1aRw6zEFJGZLtGlS5wHdiNnfURHNi3DH/OZGD6xnYIwrUGCBXsBPwr2hFDjS4EanBMC4Dh54rrEH8/PnZGcsg2BnCwewXuvydM5S9r026BLzwtQUAIOK9YD7UIDC6DRJzGGUolCO4bIEi8hChiT8tqJizP/fDi8MIhDyhIgqfQVoASfJojCIHMxbqFCj9d9ImV4TVR+GhUaWzA9uG778Jne0bDK9eB0X/H1snkHNeO4T3jGLSiKZiuBqqLoaSU9mKgEprxdP0x58jBctjWtkRqYkVkk5QhZFxJ/hUpUD4pQ4iF0tocEkMjeuez7U7uoQbe3TsnMw2S6kV1JOtjsvzTx7OXsEbnUZUmNaSMUoxruKbOqDd1xbRUujndeiDlNj71t49fpFGJNeN44fSlo7kz6HwEVf1MymyqprtJqnHKsfo4EwYOb8nWZ17wOHt0wVPMrbqW+O1pza8PL74SCfV+5PNm+gsrj0qACqkhAg+iZiVW+7TGfcm+LTs3XlsHsxcy//HCLm8eziqfn0bntAmgIHoMgEqBII8HqPLs2Jh7jjKsCbPQ5wfEA9KXHygPTugNMFRXVFH61PhV9pP1vyPMOvW/Wn46XFVDkeDPaMF9rOuFhkxqYGSEla3P/7Z9eb0rn9fprQL/BTpHpbJ8nQeEAAAAAElFTkSuQmCC'
