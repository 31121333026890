import React from 'react';
import Modal from 'react-modal';
import iconSet from '../../resources/fonts/selection.json'
import IcomoonReact from 'icomoon-react'
import { useTranslation } from 'react-i18next'
// Styles pour la modal
const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000
  },
  content: {
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '700px',
    width: '90%',
    padding: '15px',
    borderRadius: '20px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',

  }
};

const ModalComponent = ({ showModal, handleCloseModal }) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={handleCloseModal}
      contentLabel="Example Modal"
      style={customStyles}
    >
      <ul className="point-list">
        <li>{t('looking_for_a_researcher')}</li>
        <li>{t('researcher_in_need')}</li>
        <li>{t('listed_in_marketplace')}</li>
        <li>{t('General_enquiries')}</li>
        <li>{t('GDPR_request')}</li>
        <li>{t('Other')}</li>
      </ul>
      <a
        style={{ marginLeft: '10px' }}
        href="mailto:contact@adlin-science.com?Subject=Contact Linkinnov %3A %5Bvotre demande ici%5D"
      >
      <div className="button-container">
        <span
          style={{
            width: '315px',
            margin: '5px',
            textAlign: 'center'
          }}
          className="btn2 btn-light btn-purple"
        >
          <IcomoonReact
            iconSet={iconSet}
            color="#a03ee6"
            size={15}
            icon="mail"
          />
          <span style={{
            marginLeft: "5px"
          }}>
            {t('contact_usHome')} 
          </span>           
        </span>
      </div>
      </a>
    </Modal>
  );
};

export default ModalComponent;
