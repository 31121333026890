import AccueilHeader from '../accueil/accueil.component.header'
import AccueilFooter from '../accueil/accueil.component.footer'
import { LinkExpert } from './link-expert'

export default function LinkExpertNoAut() {
  return (
    <div>
      <AccueilHeader />
      <LinkExpert auth={false} />
      <AccueilFooter noInscription />
    </div>
  )
}
