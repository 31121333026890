export const search = {
  en: {
    all: 'All',
    profils: 'Profiles',
    advertisement: 'Advertisement',
    See_more_results: 'See more results',
    productions: 'Productions',
    groups: 'Groups',
    entities: 'Entities'
  },
  fr: {
    all: 'Tous',
    profils: 'Profils',
    advertisement: 'Annonces',
    See_more_results: 'Voir plus de résultats',
    productions: 'Productions',
    groups: 'Groupes',
    entities: 'Entités'
  },
  it: {
    all: 'Tutti',
    profils: 'Profili',
    advertisement: 'Annunci',
    See_more_results: 'Vedi più risultati',
    productions: 'produzioni',
    groups: 'gruppi',
    entities: 'Profili morali'
  },
  de: {
    all: 'Alle',
    profils: 'Profile',
    advertisement: 'Werbung',
    See_more_results: 'Weitere Ergebnisse anzeigen',
    productions: 'Produktionen',
    groups: 'Gruppen',
    entities: 'Moralische Profile'
  },
  es: {
    all: 'Todas',
    profils: 'Perfiles',
    advertisement: 'Anuncio',
    See_more_results: 'Ver más resultados',
    productions: 'Producciones',
    groups: 'Grupos',
    entities: 'Perfiles morales'
  }
}
