/* eslint-disable no-unused-expressions */
import React from 'react'
import './style/link-expert.scss'
import OvalHeader from '../../resources/images/linkinnov-expert-oval.svg'
// import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import TickIcon from '../../resources/images/expert-tick-blue.svg'
import DetailImage from '../../resources/images/link-expert-top-image.png'
import CornerImage from '../../resources/images/link-expert-corner-image.png'
import ConsultantImg from '../../resources/images/link-expert-consultant.png'
import ScientistImg from '../../resources/images/link-expert-scientific.png'
import TechImg from '../../resources/images/link-expert-tech.png'
import EduTechImg from '../../resources/images/link-expert-edu-tech.png'
import EduSocioImg from '../../resources/images/link-expert-edu-sociology.png'
import AccFormImg from '../../resources/images/link-expert-formation-acc.png'
//import ProductImg from '../../resources/images/link-expert-product-school.png'
import GabImg from '../../resources/images/link-expert-gabrielle.png'
import SamImg from '../../resources/images/link-expert-sam.png'
import NewModImg from '../../resources/images/link-expert-new-model.png'
import ClockImg from '../../resources/images/link-expert-clock.svg'
import PorkImg from '../../resources/images/link-expert-money-pork.svg'
import SecHarm from '../../resources/images/link-expert-secure-harmer.svg'
import CycloneImg from '../../resources/images/link-expert-cyclone.svg'
import FeatAnnounceImg from '../../resources/images/link-expert-feature-announce.svg'
import FeatMatchImg from '../../resources/images/link-expert-feature-match.svg'
import FeatChatImg from '../../resources/images/link-expert-feature-chat.svg'
import FeatContImg from '../../resources/images/link-expert-feature-contract.svg'
import FeatMissionImg from '../../resources/images/link-expert-feature-mission.svg'
import FeatPayImg from '../../resources/images/link-expert-feature-pay.svg'
import FeatDashboardImg from '../../resources/images/link-expert-feature-dashboard.svg'
import FeatAnnounceImgHover from '../../resources/images/link-expert-feature-announce-hovered.svg'
import FeatMatchImgHover from '../../resources/images/link-expert-feature-match-hovered.svg'
import FeatChatImgHover from '../../resources/images/link-expert-feature-chat-hovered.svg'
import FeatContImgHover from '../../resources/images/link-expert-feature-contract-hovered.svg'
import FeatMissionImgHover from '../../resources/images/link-expert-feature-mission-hovered.svg'
import FeatPayImgHover from '../../resources/images/link-expert-feature-pay-hovered.svg'
import FeatDashboardImgHover from '../../resources/images/link-expert-feature-dashboard-hovered.svg'
import FranceIslandImg from '../../resources/images/link-expert-france-island.png'
import FranceDomImg from '../../resources/images/link-expert-dom-map.png'
import FranceWorldImg from '../../resources/images/link-expert-remaining-world.png'
import FranceMapImg from '../../resources/images/link-expert-france-map.png'
import BottomImg from '../../resources/images/link-expert bottom-img.png'

import { useTranslation } from 'react-i18next'
// import useInscriptions from '../accueil/utils/use-inscriptions.hook'
//import ProfileSA from '../../service/applicative/profile.applicative'

const features = [
  {
    id: '1',
    image: FeatAnnounceImg,
    title: 'link-expert-feature-announce',
    descr: ['link-expert-feature-announce-descr']
  },
  {
    id: '2',
    image: FeatMatchImg,
    title: 'link-expert-feature-match',
    subtitle: 'link-expert-feature-match-subtitle',
    descr: [
      'link-expert-feature-match-descr-1',
      'link-expert-feature-match-descr-2',
      'link-expert-feature-match-descr-3'
    ]
  },
  {
    id: '3',
    image: FeatChatImg,
    title: 'link-expert-feature-chat',
    descr: ['link-expert-feature-chat-descr']
  },
  {
    id: '4',
    image: FeatContImg,
    title: 'link-expert-feature-contract',
    descr: ['link-expert-feature-contract-descr']
  },
  {
    id: '5',
    image: FeatMissionImg,
    title: 'link-expert-feature-mission',
    descr: ['link-expert-feature-mission-descr']
  },
  {
    id: '6',
    image: FeatPayImg,
    title: 'link-expert-feature-pay',
    descr: ['link-expert-feature-pay-descr']
  },
  {
    id: '7',
    image: FeatDashboardImg,
    title: 'link-expert-feature-dashboard',
    descr: ['link-expert-feature-dashboard-descr']
  }
]
const maps = [
  { id: '1', image: FranceIslandImg },
  { id: '2', image: FranceDomImg },
  { id: '3', image: FranceWorldImg }
]
const advantages = [
  {
    id: 1,
    image: ClockImg,
    title: 'link-expert-advantage-item-1-title',
    subtitle: 'link-expert-advantage-item-1-subtitle',
    detail: 'link-expert-advantage-item-1-detail'
  },
  {
    id: 2,
    image: SecHarm,
    title: 'link-expert-advantage-item-3-title',
    subtitle: 'link-expert-advantage-item-3-subtitle',
    detail: 'link-expert-advantage-item-3-detail'
  },
  {
    id: 3,
    image: PorkImg,
    title: 'link-expert-advantage-item-2-title',
    subtitle: 'link-expert-advantage-item-2-subtitle',
    detail: 'link-expert-advantage-item-2-detail'
  },
  {
    id: 4,
    image: CycloneImg,
    title: 'link-expert-advantage-item-4-title',
    subtitle: 'link-expert-advantage-item-4-subtitle',
    detail: 'link-expert-advantage-item-4-detail'
  }
]
const detailsList = [
  {
    id: 1,
    content: 'link-expert-content-1'
  },
  {
    id: 2,
    content: 'link-expert-content-1-2'
  },
  {
    id: 3,
    content: 'link-expert-content-2'
  },
  {
    id: 4,
    content: 'link-expert-content-3'
  },
  {
    id: 5,
    content: 'link-expert-content-4'
  },
  {
    id: 6,
    content: 'link-expert-content-5'
  }
]
const heirsDetail = [
  {
    id: 1,
    content: 'link-expert-heir-content-1'
  },
  {
    id: 2,
    content: 'link-expert-heir-content-2'
  },
  {
    id: 3,
    content: 'link-expert-heir-content-3'
  }
]
const categories = [
  {
    id: 1,
    image: ConsultantImg,
    content: 'link-expert-category-1',
    description: 'link-expert-category-1-descr'
  },
  {
    id: 2,
    image: ScientistImg,
    content: 'link-expert-category-2',
    description: 'link-expert-category-2-descr'
  },
  {
    id: 3,
    image: TechImg,
    content: 'link-expert-category-3',
    description: 'link-expert-category-3-descr'
  },
  {
    id: 4,
    image: EduTechImg,
    content: 'link-expert-category-4',
    description: 'link-expert-category-4-descr'
  },
  {
    id: 5,
    image: EduSocioImg,
    content: 'link-expert-category-5',
    description: 'link-expert-category-5-descr'
  },
  {
    id: 6,
    image: AccFormImg,
    content: 'link-expert-category-6',
    description: 'link-expert-category-6-descr'
  },
  {
    id: 8,
    image: GabImg,
    content: 'link-expert-category-8',
    description: 'link-expert-category-8-descr'
  },
  {
    id: 9,
    image: SamImg,
    content: 'link-expert-category-9',
    description: 'link-expert-category-9-descr'
  }
]

const InscriptionCTAs = (props) => {
  // const { showInscription, showLoginDialog } = useInscriptions()
  // const { t } = useTranslation()
  // const LoginBtn = (
  //   <div onClick={null} className={'cta-btn'}>
  //     {t('link-expert-cta-btn')}
  //   </div>
  // )
  return (
    <div className={'ctas-container with-mb'}>
      {/* {props.auth ? <Link to={'/expert'}>{LoginBtn} </Link> : LoginBtn}
      {props.auth ? null : (
        <div onClick={null} className={'cta-btn secondary'}>
          {t('link-expert-register')} ADD
        </div>
      )} */}
    </div>
  )
}
// InscriptionCTAs.propTypes = {
//   auth: PropTypes.bool
// }

///getCookie used only to detect language (à enlever quand trads dispos)
// const getCookie = (name) => {
//   let nameEQ = name + '='
//   let ca = document.cookie.split(';')
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i]
//     while (c.charAt(0) === ' ') c = c.substring(1, c.length)
//     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
//   }
//   return null
// }

const getImgFeature = (img, over) => {
  let imgToReturn = ''
  var className = img.target.className
  const classNameSplit = className.split('-')
  const lengthClass = classNameSplit.length -1
  const feature = classNameSplit[lengthClass];

  over === true ? feature === 'announce'
      ? imgToReturn = FeatAnnounceImgHover
      : feature === 'match'
      ? imgToReturn = FeatMatchImgHover
      : feature === 'chat'
      ? imgToReturn = FeatChatImgHover
      : feature === 'contract'
      ? imgToReturn = FeatContImgHover
      : feature === 'mission'
      ? imgToReturn = FeatMissionImgHover
      : feature === 'pay'
      ? imgToReturn = FeatPayImgHover
      : feature === 'dashboard'
      ? imgToReturn = FeatDashboardImgHover
      : null
    : feature === 'announce'
    ? imgToReturn = FeatAnnounceImg
    : feature === 'match'
    ? imgToReturn = FeatMatchImg
    : feature === 'chat'
    ? imgToReturn = FeatChatImg
    : feature === 'contract'
    ? imgToReturn = FeatContImg
    : feature === 'mission'
    ? imgToReturn = FeatMissionImg
    : feature === 'pay'
    ? imgToReturn = FeatPayImg
    : feature === 'dashboard'
    ? imgToReturn = FeatDashboardImg
    : null

  return imgToReturn
}

export const LinkExpert = (props) => {
  const { t } = useTranslation()
  ///A enlever quand trads dispos
  const handleMouseOver = (event) => {
    var objectToReach = event.target.className + 'descr'
    event.target.src = getImgFeature(event, true)
    var x = document.getElementById(objectToReach);
    (x !== undefined) ? (x.style.visibility = 'visible') : null
  }
  const handleMouseLeave = (event) => {
    var objectToReach = event.target.className + 'descr'
    event.target.src = getImgFeature(event, false)
    var x = document.getElementById(objectToReach);
    (x !== undefined) ? (x.style.visibility = 'hidden') : null
  }
  const DescrToDisplay = () => (
    <div className="details-list row features" style={{ position: 'relative' }}>
      {features.map((feature) => (
        <div
          key={feature.id}
          id={'imgfeature-item ' + feature.title + 'descr'}
          className="feature-item-descr"
          style={{ maxWidth: feature.descr.length === 1 ? '400px' : '1200px' }}
        >
          {feature.descr.length > 1 ? (
            feature.descr.map((index) => (
              <>
                <i
                  className={'check-step ' + feature.title}
                  style={{ marginRight: '24px' }}
                />
                {t(index)}
              </>
            ))
          ) : (
            <>
              <i
                className={'check-step ' + feature.title}
                style={{ marginRight: '24px' }}
              />
              {t(feature.descr)}
            </>
          )}
        </div>
      ))}
    </div>
  )
  return (
    <div className="link-expert-main-container">
      <div className="link-expert-container">
        <img src={OvalHeader} className={'expert-oval-image'} />
      </div>
      <div className="content-container">
        <div className="details-container">
          <p className="title">
            {t('link-expert-title')} <br />
            <span className="subtitle">{t('link-expert-why')}</span>
          </p>
          <div className="details-list">
            {detailsList.map((detail) => (
              <div className="item with-mb" key={detail.id}>
                {detail.id !== 2 ? (
                  <>
                    <div className="icon">
                      <img src={TickIcon} />
                    </div>
                    <p className="detail-content">{t(detail.content)}</p>
                  </>
                ) : (
                  <div key={detail.id}>
                    <p
                      style={{
                        marginLeft: '25px',
                        marginTop: '-19px'
                      }}
                    >
                      {t(detail.content)}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <InscriptionCTAs auth={props.auth} />
        </div>
        <div className="detail-image">
          <img src={DetailImage} />
        </div>
      </div>
      <div className={'corner-image-container'}>
        <img src={CornerImage} className={'corner-image'} />
      </div>
      <div className="content-container unfold-science column">
        <div className="details-container with-ml">
          <p className="title expert-mission">
            {t('link-expert-science-title')}{' '}
            <p className="subtitle">{t('link-expert-what-isit')}</p>
          </p>
          <p className={'note'} style={{ marginBottom: '0' }}>
            {t('link-expert-science-note')}
          </p>
          <p className={'note'} style={{ marginBottom: '0' }}>
            {t('link-expert-science-note-2')}
          </p>
          <p className={'note'}>{t('link-expert-science-note-3')}</p>
          <div className={'title-small with-mb'}>
            {t('link-expert-heir-title')}
          </div>
          <div className={'details-list'}>
            {heirsDetail.map((detail) => (
              <div key={detail.id} className="item with-mb">
                <div className="icon">
                  <img src={TickIcon} />
                </div>
                <p className="detail-content">{t(detail.content)}</p>
              </div>
            ))}
          </div>
          <div className={'title-small with-mt with-mb small'}>
            {t('link-expert-science-category-title')}
          </div>
          <div className={'note'}>{t('link-expert-science-category-note')}</div>
          <div className={'categories'}>
            {categories.map((category) =>
              category.id === 8 || category.id === 9 ? null : (
                <div key={category.id} className={'category-item'}>
                  {' '}
                  <div className="category-nonHover">
                    <img src={category.image} />
                    <div className={'category-title-container'}>
                      <p className={'category-title'}>{t(category.content)}</p>
                    </div>
                  </div>
                  <div className="category-descrOnHover">
                    {t(category.description)}
                  </div>
                </div>
              )
            )}
          </div>
          <div
            className={'categories'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              flexWrap: 'unset'
            }}
          >
            <div
              key={8}
              className={'category-item'}
              style={{ marginRight: '90px' }}
            >
              {' '}
              <div className="category-nonHover">
                <img src={categories[6].image} />
                <div className={'category-title-container'}>
                  <p className={'category-title'}>{t(categories[6].content)}</p>
                </div>
              </div>
              <div className="category-descrOnHover">
                {t(categories[6].description)}
              </div>
            </div>
            <div key={9} className={'category-item'}>
              {' '}
              <div className="category-nonHover">
                <img src={categories[7].image} />
                <div className={'category-title-container'}>
                  <p className={'category-title'}>{t(categories[7].content)}</p>
                </div>
              </div>
              <div className="category-descrOnHover">
                {t(categories[7].description)}
              </div>
            </div>
          </div>
        </div>
        <div className={'details-container with-mt-large with-ml'}>
          <p className={'title with-mb-small model'}>
            {t('link-expert-new-model-title')}{' '}
            <p className={'subtitle'}>{t('link-expert-new-model-how')}</p>
          </p>
          <div className={'content-container with-mt-small no-wrap-reverse'}>
            <div className={'detail-image with-mr'}>
              <img src={NewModImg} className={'model'} />
            </div>
            <div className={'details-container fixed-200'}>
              <p className={'title-small'} style={{ marginBottom: '19px' }}>
                {t('link-expert-new-model-subtitle')}
              </p>
              <p className={'note small-margin'}>
                {t('link-expert-new-model-description')}
              </p>
              <p className={'note small-margin'}>
                {t('link-expert-new-model-description-2')}
              </p>
              <p className={'note small-margin'}>
                {t('link-expert-new-model-description-3')}
              </p>
            </div>
          </div>
        </div>
        <div className={'details-container with-mt-large with-ml full'}>
          <p className={'title'}>
            {t('link-expert-advantages-title')}{' '}
            <span className={'subtitle'}>
              {t('link-expert-advantages-subtitle')}
            </span>
          </p>
          <div className={'details-list row'}>
            {advantages.map((advantage) => (
              <div key={advantage.id} className={'advantage-item'}>
                <img src={advantage.image} />
                <p className={'advantage-item-title'}>{t(advantage.title)}</p>
                <p className={'advantage-item-subtitle'}>
                  {t(advantage.subtitle)}
                </p>
                <p className={'advantage-item-detail'}>{t(advantage.detail)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="content-container col simplicity">
        <div className="details-container center">
          <span className="title capitalize no-mb center full">
            {t('link-expert-simplicity-title')}
          </span>
        </div>
      </div>
      <div className="content-container mt-120px">
        <div className="details-container">
          <p className="title max-w-large great-stages">
            {t('link-expert-great-stages-title')}{' '}
            <p className="subtitle">{t('link-expert-great-stages-subtitle')}</p>
          </p>
          <p className="title-small great-stages">
            {t('link-expert-great-stages-note-title')}
          </p>
        </div>
      </div>
      <div className="features-descr-img-container">
        <DescrToDisplay />
        <div className="content-container features">
          <div className="details-container features">
            <div className="details-list row features">
              {features.map((feature) => (
                <div
                  key={feature.id}
                  className={'feature-item ' + feature.title}
                >
                  <img
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                    src={feature.image}
                    className={'imgfeature-item ' + feature.title}
                  />
                  <div className="feature-item-title">{t(feature.title)}</div>
                  {feature.subtitle && (
                    <div className="feature-item-subtitle">
                      {t(feature.subtitle)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="corner-image-container deep">
        <img src={CornerImage} className="corner-image deep" />
      </div>
      <div className="content-container unfold-joint column">
        <div
          className="details-container"
          style={{ margin: 'auto', textAlign: 'center' }}
        >
          <p className="title joint">
            {t('link-expert-joint-title')}{' '}
            <p className={'subtitle'}>{t('link-expert-joint-subtitle')}</p>
          </p>
        </div>
        <div className="content-container reverse no-mt">
          <div className="details-container map-image-list">
            <div className="details-list">
              {maps.map((map) => (
                <div key={map.id} className={'map-image-item'}>
                  <img src={map.image} />
                </div>
              ))}
            </div>
          </div>
          <div className="detail-image">
            <img src={FranceMapImg} className={'map'} />
          </div>
        </div>
      </div>
      <div className="corner-image-container bottom-image">
        <img src={BottomImg} className={'corner-image bottom-image'} />
      </div>
      <div className={'relative flex justify-center'}>
        <div className="content-container unfold-bottom">
          <div className="details-container">
            <span className="title">
              {t('link-expert-join-title')}{' '}
              <span className="subtitle">{t('link-expert-join-subtitle')}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

// LinkExpert.propTypes = {
//   auth: PropTypes.bool
// }
