import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';

import './resources/index.css';
import { jssPreset } from '@mui/styles'
import { createBrowserHistory } from 'history'

import jssExtend from 'jss-extend'
import { create } from 'jss'

import { I18nextProvider } from 'react-i18next'
import i18n from './common/i18n/i18n'
import App from './App';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()]
})

jss.options.insertionPoint = document.getElementById('jss-insertion-point')
const history = createBrowserHistory()


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router history={history}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Router>
  </React.StrictMode>
);
