import React, { useState } from 'react';
import twitterLogo from '../../resources/images/twitter.svg'
import linkedinLogo from '../../resources/images/linkedin.svg'
import PropTypes from 'prop-types'
import { HashLink as Link } from 'react-router-hash-link'
import logoLink from '../../resources/images/logo.svg'
import logoLink2 from '../../resources/images/Logo vertical_sans baseline.png'
import iconSet from '../../resources/fonts/selection.json'
import IcomoonReact from 'icomoon-react'
import { useTranslation } from 'react-i18next'
// import useInscriptions from './utils/use-inscriptions.hook'
import logoCAI from '../../resources/images/logo_cai.png'
import ModalComponent from '../modal/ModalComponent';

export default function AccueilFooter(props) {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // const { showInscription } = useInscriptions()
  return (
    <footer>
      {props.noInscription ? null : (
        <section className="flex-container free-premium">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="text-container">
              <p>
                <span style={{ fontWeight: '700' }}>
                  {t('footer_first_section_title')}
                </span>
              </p>
            </div>
            <div>
              <span
                style={{
                  width: '315px',
                  margin: '5px',
                  textAlign: 'center'
                }}
                onClick={handleOpenModal}
                className="btn2 btn-light btn-purple"
              >
                <IcomoonReact
                  iconSet={iconSet}
                  color="#a03ee6"
                  size={15}
                  icon="mail"
                />
                <a style={{ marginLeft: '10px' }}>
                  {t('contact_usHome')}
                </a>
              </span>
              <ModalComponent
                showModal={showModal}
                handleCloseModal={handleCloseModal}
              />
            </div>
          </div>
        </section>
      )}

      <nav className="flex-container footer-nav">
        <div className="logo-container footer-element">
          {/* <Link smooth to="#mainSection">
            <img
              style={props.cai ? { maxWidth: '60%' } : {}}
              src={props.cai ? logoLink2 : logoLink}
              alt="Linkinnov"
            />
          </Link> */}
          <p>contact@adlin-science.com</p>
          <div
            className="socials-container footer-element"
            style={{ justifyContent: 'unset', marginTop: '15px' }}
          >
            <a
              href="https://twitter.com/AdlinScience"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterLogo} alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/adlin-science/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedinLogo} alt="linkedin" />
            </a>
          </div>
        </div>

        {props?.cai ? (
          <>
            <div
              className="logo-container footer-element"
              style={{ marginLeft: '-25%' }}
            >
              {/* <Link smooth to="#mainSection">
                <img
                  style={{ maxWidth: '65%' }}
                  src={logoCAI}
                  alt="Linkinnov"
                />
              </Link> */}
              <p>contact@clermontauvergneinnovation.com</p>
              <p style={{ marginTop: ' 5px 0px 5px 0px' }}>+33 4 73 60 18 30</p>
              <div
                className="socials-container footer-element"
                style={{
                  justifyContent: 'unset',
                  marginTop: '15px'
                }}
              >
                <a
                  href="https://twitter.com/CAI_Clermont"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitterLogo} alt="twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/satt-grand-centre"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedinLogo} alt="linkedin" />
                </a>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        <div className="links-container footer-element">
          <ul className="landing-page">
            <li>
              <a href="/conditionsGenerales">
                <span className="nav-text">{t('CGS')}</span>
              </a>
            </li>
            <li>
              <a href="/conditionsUtilisations">
                <span className="nav-text">{t('CGU')}</span>
              </a>
            </li>
            <li>
              <a href="/mentionsLegales">
                <span className="nav-text">{t('legal_notice')}</span>
              </a>
            </li>
            <li>
              {/* <Link
                to={{
                  pathname: 'https://linkinnov.com/#/mention-legale'
                }}
                target="_blank"
              >
                <span className="nav-text">{t('legal_notice')}</span>
              </Link> */}
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  )
}

AccueilFooter.propTypes = {
  noInscription: PropTypes.bool
}
