import React from 'react'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import loadable from '@loadable/component'

import LinkExpert from './presentation/link-expert/link-expert-noauth'

const Accueil = loadable(() => import('./presentation/accueil/accueil.component'))
const CGS = loadable(() => import('./presentation/legal/conditions.generales'))
const CGU = loadable(() => import('./presentation/legal/conditions.utilisation'))
const MentionsLegales = loadable(() => import('./presentation/legal/mentions.legales'))



const RouteApp = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Accueil} />
        <Route path={'/devenezExpert'} component={LinkExpert} />
        <Route path={'/conditionsGenerales'} component={CGS} />
        <Route path={'/conditionsUtilisations'} component={CGU} />
        <Route path={'/mentionsLegales'} component={MentionsLegales} />
      </Switch>
    </Router>
  )}
  export default RouteApp
