/* eslint-disable react/prop-types */
import React from 'react'
import logoFr from '../../resources/images/drapeaux_fr.svg'
import logoIt from '../../resources/images/flag_it.svg'
import logoEn from '../../resources/images/drapeaux_en.svg'
import logoDe from '../../resources/images/flag_de.svg'
import logoEs from '../../resources/images/flag_es.svg'
import arrow from '../../resources/images/small_chevron_arrow_down.png'
import { withTranslation } from 'react-i18next'

class Dropdown extends React.Component {
  constructor(props) {
    super(props)
  }
  getLanguagesArray = () => {
    const { t } = this.props
    return [
      { languageKey: 'en', langname: t('english'), logo: logoEn },
      { languageKey: 'fr', langname: t('french'), logo: logoFr }
    ]
  }

  state = {
    filterLanguages: false,
    selectedLanguage: {},
    languagesArray: this.getLanguagesArray(),
    imgSrc: logoEn,
    displayMenu: false,
    isDeviceMobil: null
  }

  getCookie = (name) => {
    let nameEQ = name + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  UNSAFE_componentWillMount = () => {
    let defaultLanguage = this.getCookie('i18next')
    ///if à remove quand trads it/es/de de DevenezExpert sont good
    window.addEventListener('resize', this.getDeviceType.bind(this))
    if (!['en', 'fr', 'es', 'it', 'de'].includes(defaultLanguage)) {
      defaultLanguage = navigator.language.substr(0, 2) || 'fr'
      document.cookie = 'i18next=' + defaultLanguage
    }
    let arrayPosition = 0
    let arr = this.state.languagesArray
    this.state.languagesArray.forEach((lang, i) => {
      if (lang.languageKey === defaultLanguage) {
        this.setState({
          selectedLanguage: lang
        })
        arrayPosition = i
      }
    })
    arr.splice(arrayPosition, 1)
    this.setState({
      languagesArray: arr
    })
    this.changeLanguageOptions()
  }

  changeLanguageOptions = () => {
    this.props.filterLanguages
      ? this.setState({ filterLanguages: this.props.filterLanguages })
      : window.location.hash == '#/DevenezExpert'
      ? this.setState({ filterLanguages: ['it', 'es', 'de'] })
      : this.setState({ filterLanguages: null })
  }

  componentDidMount = () => {
    this.setState({})
    this.getDeviceType()
  }

  getDeviceType = () =>
    this.setState({
      isDeviceMobil:
        (window.innerWidth && window.innerWidth <= 991) ||
        (document.documentElement.clientWidth &&
          document.documentElement.clientWidth <= 991)
    })

  showDropdownMenu = (event) => {
    this.changeLanguageOptions()
    event.preventDefault()
    event.stopPropagation()
    if (this.state.displayMenu !== true) {
      this.setState({ displayMenu: true }, () => {
        document.addEventListener('click', this.hideDropdownMenu)
      })
    } else this.hideDropdownMenu()
  }

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu)
    })
  }

  changeLanguage = (lang, i) => {
    let newarr = this.state.languagesArray
    newarr[i] = this.state.selectedLanguage
    this.setState({
      languagesArray: newarr
    })
    this.setState({
      selectedLanguage: lang
    })
    document.cookie = 'i18next=' + lang.languageKey
    window.location.reload()
  }

  render() {
    return (
      <div className="langDropdown">
        <div className="langDropDownBtn" onClick={this.showDropdownMenu}>
          <img
            className="dropDownImg"
            src={this.state.selectedLanguage.logo}
            alt="Logo"
          />
        </div>
        <img className="dropArrow" src={arrow} alt="Logo" />
        {this.state.displayMenu && (
          <ul>
            {this.state.languagesArray.map((data, i) => (
              <li key={i}>
                <a
                  className={`langDropdownA ${
                    this.state.filterLanguages &&
                    this.state.filterLanguages.includes(data.languageKey)
                      ? 'disable'
                      : ''
                  }`}
                  onClick={() =>
                    this.state.filterLanguages &&
                    this.state.filterLanguages.includes(data.languageKey)
                      ? null
                      : this.changeLanguage(data, i)
                  }
                >
                  <img className="dropDownImg " src={data.logo} alt="Logo" />
                  <p>{data.langname}</p>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

export default withTranslation()(Dropdown)
