import { useTranslation } from 'react-i18next'
import React, { useState } from 'react';
// import useInscriptions from './utils/use-inscriptions.hook'
import { HashLink as Link } from 'react-router-hash-link'
import logoLink from '../../resources/images/logo.svg'
import { logoImageMobile } from '../../data/constants/images'
import Dropdown from '../../common/Dropdown'
import picto_connexion from '../../resources/images/picto_connexion.svg'
import Logo_anr from '../../resources/images/logo-ANR.png'
import Logo_fr_2030 from '../../resources/images/logo-Fr_2030.jpg'
import Logo_mesri from '../../resources/images/logo_mesri.png'
import Logo_ministere_economie from '../../resources/images/logo_economie.png'
import BPILogo from '../../resources/images/bpi.png'
import ModalComponent from '../modal/ModalComponent';
export default function AccueilHeader(props) {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // const { showInscription, showLoginDialog } = useInscriptions()
  return (
    <header
      onLoad={() => {
        document.getElementById('root').className = 'with_header landing_page'
      }}
      style={{
        boxShadow: ' 0 1px 3px rgba(0,0,0,.1)',
        position: 'fixed',
        width: '100%',
        zIndex: '1000',
        top: 0,
        left: 0
      }}
      className="header"
    >
      <nav
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div className="logo-container" style={{ width: '70%' }}>
          <Link smooth to="/">
            <img src={logoLink} alt="Linkinnov" className="m-hide_488" />
            <img
              src={logoImageMobile}
              alt="LinkInnov"
              className="m-show_488 logo_mobile"
            />
          </Link>
        </div>
        <div className="partner-container">
          <ul
            className="projects-list flex-container"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <li>
              <img src={Logo_fr_2030} className="users" />
            </li>
            <li>
              <img
                src={Logo_mesri}
                style={{ maxWidth: '80%' }}
                className="users"
                alt='mesri'
              />
            </li>
            <li>
              <img
                src={Logo_ministere_economie}
                style={{ maxWidth: '75%' }}
                className="users"
                alt='ministere_economie'
              />
            </li>
            <li>
              <img
                src={BPILogo}
                style={{ maxWidth: '90%' }}
                className="users"
                alt='BPI_logo'
              />
            </li>
            <li>
              <img
                src={Logo_anr}
                style={{ maxWidth: '90%' }}
                className="users"
                alt='logo_ANR'
              />
            </li>
          </ul>
        </div>
        <div className="nav-tools">
          <div
            onClick={null}
            // removed showLoginDialog
            style={{
              fontWeight: '600',
              marginTop: '10px',
              display: 'flex',
              marginRight: '20px'
            }}
          >
            <img
              src={picto_connexion}
              style={{
                marginRight: '-10px',
                height: '20px',
                marginTop: '8px'
              }}
            />
            
          </div>
          <div onClick={null}>
            {/* removed showInscription */}
            <span
              onClick={handleOpenModal}
              className="btn2 btn-gradient2 popin-action whitespace-nowrap"
              aim=".registration-popin"
            >
              {t('joinHome')}
            </span>
            <ModalComponent
              showModal={showModal}
              handleCloseModal={handleCloseModal}
            />
          </div>
          <div style={{ marginLeft: '20px' }}>
            <Dropdown {...props} />
          </div>
        </div>
      </nav>
    </header>
  )
}
